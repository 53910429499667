import React from "react"
import {FormattedMessage, Link, useIntl} from "gatsby-plugin-intl"
import Layout from "../components/layout"
import hole3 from "../assets/images/holes/hole-3.jpg"
import hole9 from "../assets/images/holes/hole-9.jpg"
import hole11 from "../assets/images/holes/hole-11.jpg"
import hole14 from "../assets/images/holes/hole-14.jpg"
import hole16 from "../assets/images/holes/hole-16.jpg"
import logoAClub from "../assets/images/logo/logo-16.svg"
import prizeClovis from "../assets/images/prizes/clovis.svg"
import prizeKurz from "../assets/images/prizes/kurz.svg"
import prizeBmw from "../assets/images/prizes/bmw.svg"
import prizeDelcokartell from "../assets/images/prizes/delcokartell.svg"
import prizeNewline from "../assets/images/prizes/newline.svg"
import prizeAClub from "../assets/images/prizes/aclub.svg"
import icoTicket from "../assets/images/icons/ticket.svg"
import prizes from "../assets/images/prizes.jpg"
import ervLogo from "../assets/images/erv-logo.jpg"
import SEO from "../components/seo"
import {Row, Col, Card, Divider, Button, Alert} from "antd";
import {CalendarOutlined, LinkOutlined} from "@ant-design/icons";
import {utils} from "../services/utils";

const {Meta} = Card;

const PrizesPage = () => {
    const intl = useIntl()
    return (
        <Layout>
            <SEO
                lang={intl.locale}
                title={`Premi | ${intl.formatMessage({ id: "title" })}`}
                keywords={["wuerth, wuerth open, open lugano, golf, wuerth golf open, golf lugano"]}
            />
            <div className="page-prizes">
                <div className="container text-center pt-4">
                    <h1>I premi</h1>
                    <p className="mb-0">In caso di hole in one sulle buche par 3, Wūrth FS Open mette in palio un
                        montepremi pari a <span className="fw-bold">CHF 100'000</span>!</p>
                    <p>Grazie a <a href="https://www.erv.ch/" target="_blank">ERV - Europäische Reiseversicherung.</a>
                    </p>
                    <a href="https://www.erv.ch/" target="_blank">
                        <img src={ervLogo} alt="ERV" className="erv-logo my-3"/>
                    </a>
                </div>
                <section className="pt-4">
                    <div className="container">
                        <Row gutter={24} type="flex">
                            <Col xs={24} className="text-center mb-4">
                                <img src={prizes} alt="Premi" className="prizes-img"/>
                                <h4 className="text-primary text-uppercase fw-bold">
                                    Super premi hole in one e premi a estrazione
                                </h4>
                                <Divider/>
                            </Col>
                            <Col xs={12} lg={8}>
                                <Card
                                    bordered
                                    className="shadow text-center mb-4">
                                    <h5 className="text-uppercase fw-bold text-primary">Buca 3</h5>
                                    <Alert className="my-3" type="success"
                                           message={<h3 className="m-0 p-0 fw-bold">1x Buono CHF 20'000</h3>}/>
                                    <Link to={"/sponsor/3-clovis-sail-yacht"}>
                                        <img src={prizeClovis} alt="Clovis" className="prize-logo"/>
                                    </Link>
                                    <h4 className="fw-bold mb-1">Clovis Sail Yacht</h4>
                                    <p>Per una settimana di vacanza in barca a vela nel Mar Mediterraneo</p>
                                    <img alt="Wuerth Open" className="hole-img" src={hole3}/>
                                </Card>
                            </Col>
                            <Col xs={12} lg={8}>
                                <Card
                                    bordered
                                    className="shadow text-center mb-4">
                                    <h5 className="text-uppercase fw-bold text-primary">Buca 9</h5>
                                    <Alert className="my-3" type="success"
                                           message={<h3 className="m-0 p-0 fw-bold">1x Buono CHF 20'000</h3>}/>
                                    <Link to={"/sponsor/9-kurz"}>
                                        <img src={prizeKurz} alt="Kurz" className="prize-logo"/>
                                    </Link>
                                    <h4 className="fw-bold mb-1">Gioielleria Kurz</h4>
                                    <p>Per acquisto in negozio a Lugano</p>
                                    <img alt="Wuerth Open" className="hole-img" src={hole9}/>
                                </Card>
                            </Col>
                            <Col xs={12} lg={8}>
                                <Card
                                    bordered
                                    className="shadow text-center mb-4">
                                    <h5 className="text-uppercase fw-bold text-primary">Buca 11</h5>
                                    <Alert className="my-3" type="success"
                                           message={<h3 className="m-0 p-0 fw-bold">1x Buono CHF 20'000</h3>}/>
                                    <Link to={"/sponsor/11-bmw-emil-frey"}>
                                        <img src={prizeBmw} alt="BMW" className="prize-logo"/>
                                    </Link>
                                    <h4 className="fw-bold mb-1">BMW Emil Frey - Lamone</h4>
                                    <p>Per acquisto in concessionaria</p>
                                    <img alt="Wuerth Open" className="hole-img" src={hole11}/>
                                </Card>
                            </Col>
                            <Col xs={12} lg={8}>
                                <Card
                                    bordered
                                    className="shadow text-center">
                                    <h5 className="text-uppercase fw-bold text-primary">Buca 14</h5>
                                    <Alert className="my-3" type="success"
                                           message={<h3 className="m-0 p-0 fw-bold">2x Buono CHF 10'000</h3>}/>
                                    <Link to={"/sponsor/14-kartell"}>
                                        <img src={prizeDelcokartell} alt="Delcò - Kartell" className="prize-logo"/>
                                    </Link>
                                    <h4 className="fw-bold mb-1">Delcò Mobili / Kartell</h4>
                                    <p>Per acquisto in negozio a Lugano</p>
                                    <img alt="Wuerth Open" className="hole-img" src={hole14}/>
                                </Card>
                            </Col>
                            <Col xs={12} lg={8}>
                                <Card
                                    bordered
                                    className="shadow text-center">
                                    <h5 className="text-uppercase fw-bold text-primary">Buca 16</h5>
                                    <Alert className="my-3" type="success"
                                           message={<h3 className="m-0 p-0 fw-bold">1x Buono CHF 20'000</h3>}/>
                                    <Link to={"/sponsor/12-newline"}>
                                        <img src={prizeNewline} alt="New Line" className="prize-logo"/>
                                    </Link>
                                    <h4 className="fw-bold mb-1">New Line</h4>
                                    <p>Per acquisto in negozio</p>
                                    <img alt="Wuerth Open" className="hole-img" src={hole16}/>
                                </Card>
                            </Col>
                            <Col xs={12} lg={8}>
                                <Card
                                    bordered
                                    className="shadow text-center">
                                    <h5 className="text-uppercase fw-bold text-primary">Premio speciale a
                                        estrazione</h5>
                                    <Alert className="my-3" type="warning"
                                           message={<h3 className="m-0 p-0 fw-bold">10x Buono CHF 200</h3>}/>
                                    <Link to={"/sponsor/16-aclub"}>
                                        <img src={prizeAClub} alt="A-Club" className="prize-logo"/>
                                    </Link>
                                    <h4 className="fw-bold mb-1">A-Club Fitness & Wellness</h4>
                                    <p>Per acquisto al Centro sportivo di Savosa</p>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default PrizesPage
